<template>
  <div class="app-box">
    <x-screen-tab :bindYear="selectObject.year" :bindMonth="selectObject.month||0" @change="screenChange"></x-screen-tab>
    <x-scroll-view :top="0.52">
      <table v-if="xhrData&&xhrData.items.length>0" class="data-table double-table" style="width:6.2rem;">
        <colgroup>
          <col style="width:1.6rem;">
          <col style="width:1.6rem;">
          <col style="width:1rem;">
          <col style="width:1rem;">
          <col style="width:1rem;">
        </colgroup>
        <thead>
          <tr>
            <th>
              <span>发货方</span>
            </th>
            <th scope="col">
              <span>收货方</span>
            </th>
            <th scope="col">
              <span>业务员</span>
            </th>
            <th scope="col">
              <span>回款数量</span>
            </th>
            <th scope="col">
              <span class="table-sort-icon" :class="[sort?'table-sort-down-icon':'table-sort-up-icon']" @click="sortEvent">回款金额(元)</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in xhrData.items" :key="index">
            <th scope="row">{{item.shipperName}}</th>
            <td>{{item.companyName}}</td>
            <td>{{item.sellerName}}</td>
            <td>{{item.backCount}}</td>
            <td>{{item.backAmount|formatNum}}</td>
          </tr>
        </tbody>
      </table>
      <x-no-data-bg v-if="ajaxEnd&&xhrData&&xhrData.items.length<=0"></x-no-data-bg>
    </x-scroll-view>
  </div>
</template>

<script>
import scrollView from "@/components/scrollView";
import screenTab from "@/components/screenTab";
import noDataBg from "@/components/noDataBg";
import { countDetailFlow } from "@/api/reportCenter";
import { formatNum } from "@/script/tools";

export default {
  data () {
    return {
      sort: true,
      xhrData: null,
      ajaxEnd: false,
      selectObject: {
        goodsAbbreviatedName: this.$route.params.goodsAbbreviatedName,
        level2Item: this.$route.params.level2Item,
        year: this.$route.params.year,
        month: parseInt(this.$route.params.month) || null,
        queryTypeValue: 1
      }
    };
  },
  mixins: [formatNum],
  created () {
    document.title = this.selectObject.level2Item + this.selectObject.goodsAbbreviatedName;
    this.getDataList();
  },
  methods: {
    getDataList () {
      countDetailFlow(this.selectObject).then(xhr => {
        this.xhrData = xhr.data;
        this.ajaxEnd = true;
      });
    },
    sortEvent () {
      this.sort = !this.sort;
      this.xhrData.items.reverse();
    },
    screenChange (data) {
      this.selectObject.year = data.year.code;
      this.selectObject.month = data.month.code || null;
      this.getDataList();
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.index <= from.meta.index) {
        document.title = this.selectObject.level2Item + this.selectObject.goodsAbbreviatedName;
      }
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [screenTab.name]: screenTab,
    [noDataBg.name]: noDataBg
  }
};
</script>

<style scoped>
</style>
